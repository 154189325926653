import SelectItemOption from "../option/option.component";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";

import './select.styles.scss'

const customStyles = {
  control: (base, state) => ({
    ...base,
    minHeight: 46,
    borderRadius: 0,
    boxShadow: "none",
    borderColor: state.isFocused ? "#CED1D9" : "#CED1D9",
    "&:hover": {
      borderColor: state.isFocused ? "#CED1D9" : "#CED1D9",
    }
  })
};
const Select = ({ value, options, onChange, compact,
                  isMulti = true, closeMenuOnSelect = false, displayResultCount = true,
                  isSearchable = true, predictedCountInfo={}}) => {
  const [selectedOption, setSelectedOption] =
    useState(value ? options.filter(option => value.includes(option.value)) : null);

  useEffect(() => {
    function setSelected() {
      setSelectedOption(value ? options.filter(option => value.includes(option.value)) : null)
    }
    setSelected();
  }, [value, options])

  const _handleChange = (_selected) => {
    setSelectedOption(_selected);
    if (isMulti === true) {
      onChange(_selected.map(item => item.value));
    } else {
      onChange(_selected.value);
    }
  }

  return (
    <div className={`select-container ${!compact ? 'select-container-desktop' : ''}`}>
      <ReactSelect
        value={selectedOption}
        isMulti={isMulti}
        closeMenuOnSelect={compact}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
        selectProps={{displayResultCount, predictedCountInfo}}
        components={{
          Option: SelectItemOption
        }}
        onChange={_handleChange}
        isSearchable={!compact}
        menuIsOpen={!compact ? true : undefined}
        captureMenuScroll={false}
        menuShouldScrollIntoView={compact}
        autoFocus={false}
        allowSelectAll={true}
        options={options}
        styles={customStyles}
        placeholder={compact ? 'Select...' : 'Type or Select...'}
        className="react-select-container"
        classNamePrefix="react-select"
      />
    </div>
  )
}

export default Select;
