import { SEARCH_BOX_ACTION_TYPES } from "./search-box.types";

const INITIAL_STATE = {
  isSearchBoxOpen: false
}
export const searchBoxReducer = (state = INITIAL_STATE , action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_BOX_ACTION_TYPES.SET_IS_SEARCH_BOX_OPEN:
      return { ...state, isSearchBoxOpen: payload };
    default:
      return state;
  }
}
