import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { booksSelectors } from "../../store/books/books.selectors";
import { useParams } from "react-router-dom";

const SelectLevels = ({ levels }) => {
  const [book, setBook] = useState({});
  const booksMap = useSelector(booksSelectors.selectAllBooksAsMap);
  const { id } = useParams();

  useEffect(() => {
      const book = booksMap.get(id);
      setBook(book);
    },
    [id, booksMap]);

  return (
    <>
      <div className="book-levels">
        {
          levels.map(level => (
            <div className="level-link-container" key={level.link}>
              <a href={level.link}>
                <div className="level">{level.level}</div>
              </a>
            </div>
          ))
        }
      </div>
      <div>Suggested English level for comfortable reading: <b>{book.SuggestedLevel}</b></div>
    </>
  )
}

export default SelectLevels;
