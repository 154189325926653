import { useEffect, useState } from "react";
import Select from "../select/select.component";
import SearchInput from "../search-input/search-input.component";
import { Button } from "react-bootstrap";

import './search-box.styles.scss'
import { useSearchParams } from "react-router-dom";
import { useDesktopCheck, useNavigateSearch } from "../../utils/custom-hooks/custom-hooks.utils";
import { useSelector } from "react-redux";
import { booksSelectors } from "../../store/books/books.selectors";
import { convertSearchParamsToObject } from "../../utils/params/params.utils";
import { getPredictedCountInfo } from "../../utils/books/books.utils";

const SearchBox = ({ onSearch, compact }) => {

  const [filters, setFilters] = useState({});
  const [predictedCountInfo, setPredictedCountInfo] = useState({});

  const booksList = useSelector(booksSelectors.selectAllBooks);
  const booksIndex = useSelector(booksSelectors.selectAllBooksIndex);
  const booksMap = useSelector(booksSelectors.selectAllBooksAsMap);
  const categories = useSelector(booksSelectors.selectUsedCategoriesAsOptions);
  const authors = useSelector(booksSelectors.selectUsedAuthorsAsOptions);
  const languages = useSelector(booksSelectors.selectUsedLanguagesAsOptions);

  const navigateSearch = useNavigateSearch();
  const isDesktop = useDesktopCheck();
  const [searchParams] = useSearchParams();


  useEffect(() => {
    setFilters(convertSearchParamsToObject(searchParams));
  }, [searchParams])

  useEffect(() => {
    const getPredictedCountInfoHelperFn = async () => {
      const predicted = await getPredictedCountInfo(booksMap, booksIndex, {
        categories, authors, languages
      }, filters);
      setPredictedCountInfo(predicted);
    };

    getPredictedCountInfoHelperFn().then(()=> () => null);
  }, [booksList, booksIndex, booksMap, filters, categories, authors, languages])

  const handleLanguagesChange = (selectedLanguages) => {
    filters.l = selectedLanguages;
    setFilters(filters);
    isDesktop && navigateSearch(filters);
  }

  const handleCategoriesChange = (selectedCategories) => {
    filters.c = selectedCategories;
    setFilters(filters);
    isDesktop && navigateSearch(filters);
  }

  const handleAuthorsChange = (selectedAuthors) => {
    filters.a = selectedAuthors;
    setFilters(filters);
    isDesktop && navigateSearch(filters);
  }

  const handleSearchInputChange = (searchInputValue) => {
    filters.q = searchInputValue;
    setFilters(filters);
  }

  const handleSearchClick = () => {
    if (onSearch) {
      onSearch();
    }
    navigateSearch(filters);
  }

  return (
    <div className={`search-box-container ${!compact ? 'search-box-container-desktop' : ''}`}>
      <div className="search-input-group">
        {
          compact && <div className="label">SEARCH</div>
        }
        <div className="search-input">
          <SearchInput onChange={handleSearchInputChange}
                       value={filters?.q}
                       onSearchClick={handleSearchClick}
                       compact={compact}/>
        </div>
      </div>
      <div className="search-input-group">
        <div className="label">DICTIONARY LANGUAGE</div>
        <div className="search-input language-select">
          <Select name="language-select"
                  isMulti={false}
                  onChange={handleLanguagesChange}
                  value={filters?.l}
                  options={languages}
                  predictedCountInfo={predictedCountInfo.l}
                  compact={compact}/>
        </div>
      </div>
      <div className="search-input-group">
        <div className="label">BOOK CATEGORY</div>
        <div className="search-input">
          <Select name="category-select"
                  onChange={handleCategoriesChange}
                  value={filters?.c}
                  options={categories}
                  predictedCountInfo={predictedCountInfo.c}
                  compact={compact}/>
        </div>
      </div>
      <div className="search-input-group">
        <div className="label">AUTHOR</div>
        <div className="search-input">
          <Select name="author-select"
                  onChange={handleAuthorsChange}
                  value={filters?.a}
                  options={authors}
                  predictedCountInfo={predictedCountInfo.a}
                  compact={compact}/>
        </div>
      </div>
      {
        compact &&
        <Button className="filter-button" variant="primary" onClick={handleSearchClick}>Filter</Button>
      }
    </div>
  )
}

export default SearchBox;
