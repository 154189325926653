const Faq = () => {
  return (<div>
    <h2>FAQ</h2>

    <p>
      <b><i>Why are multiple meanings of a word given in the dictionary?</i></b><br />
      Our books are educational, we want readers to get acquainted with the different meanings of a word. What's more,
      we encourage readers to interpret the text on their own - such intellectual engagement gives the most educational
      benefits.
    </p>

    <p>
      <b><i>Where can I buy printed books?</i></b><br />
      Printed books [with a dictionary] or ebooks will be available for purchase from Amazon's bookstore.
    </p>
    <p>
      <b><i>Do you plan to publish contemporary titles?</i></b><br />
      We have made efforts to buy the copyrights to contemporary titles. We hope to expand our offer with such editions
      soon.
    </p>
    <p>
      <b><i>Do the books contain original texts or adaptations?</i></b><br />
      All our publications contain original content without shortening or simplification. We have also adopted the
      principle of leaving the original spelling (for example, the form of dialogue writing, which is specific in
      English literature).
    </p>
    <p>
      <b><i>What are the levels of advancement of our editions?</i></b><br />
      Each of our online publications can be displayed with a dictionary adapted to the reader's level of English
      proficiency. We offer publications for people with at least A1, A2, B1, B2 and C1 levels of knowledge.
    </p>
    <p>
      <b><i>Do you plan to publish books in other languages?</i></b><br />
      Yes, we currently have books in German and Spanish in the pipeline, and in the near future our offer will also
      expand to include editions in French, Italian and Portuguese.
    </p>
    <p>
      <b><i>Are audio versions of your publications available?</i></b><br />
      At the moment we do not have such capabilities, but as our business grows, we intend to prepare a feature with
      voice.
    </p>
  </div>);
}

export default Faq;
