import {
  convertTypes,
  createIndex,
  createLanguageOptions, createMapById,
  createOptionsFromField, getUniqueFields
} from "../../utils/books/books.utils";
import { createSelector } from "reselect";


const selectBooksReducer = (state) => state.books;

const selectAllBooks = createSelector(
  [selectBooksReducer],
  (books) => convertTypes(books.booksList));

const selectAllUniqueFields = createSelector(
  [selectAllBooks],
  (booksList) => getUniqueFields(booksList));

const selectAllBooksIndex = createSelector(
  [selectAllBooks, selectAllUniqueFields],
  (booksList, uniqueFields) => createIndex(booksList, uniqueFields));

const selectAllBooksAsMap = createSelector(
  [selectAllBooks],
  (booksList) => createMapById(booksList));

const selectUsedCategoriesAsOptions = createSelector(
  [selectAllBooks],
  (booksList) => createOptionsFromField(booksList, "BookCategory"));

const selectUsedAuthorsAsOptions = createSelector(
  [selectAllBooks],
  (booksList) => createOptionsFromField(booksList, "BookAuthor"));

const selectUsedLanguagesAsOptions = createSelector(
  [selectAllBooks],
  (booksList) => createLanguageOptions(booksList));

const selectError = createSelector(
  [selectBooksReducer],
  (books) => books.error);

const selectBooksIsLoading = createSelector(
  [selectBooksReducer],
  (books) => books.isLoading);

export const booksSelectors = {
  selectAllBooks,
  selectAllBooksIndex,
  selectUsedCategoriesAsOptions,
  selectUsedAuthorsAsOptions,
  selectUsedLanguagesAsOptions,
  selectError,
  selectBooksIsLoading,
  selectAllBooksAsMap
}
