import { createAction } from "../../utils/reducer/reducer.utils";
import { BOOKS_ACTION_TYPES } from "./books.types";
import { fetchBooks } from "../../utils/http/http.utils";

export const booksActions = {
  fetchBooksStart: () => createAction(BOOKS_ACTION_TYPES.FETCH_BOOKS_START),
  fetchBooksSuccess: (bookList) => createAction(BOOKS_ACTION_TYPES.FETCH_BOOKS_SUCCESS, bookList),
  fetchBooksFailed: (error) => createAction(BOOKS_ACTION_TYPES.FETCH_BOOKS_FAILED, error),
  fetchBooksAsync: () => async (dispatch) => {
    dispatch(booksActions.fetchBooksStart());
    try {
      const booksList = await fetchBooks()
      dispatch(booksActions.fetchBooksSuccess(booksList));
    } catch (e) {
      dispatch(booksActions.fetchBooksFailed(e.message));
    }
  }
}
