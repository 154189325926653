import { useSelector } from "react-redux";
import { booksSelectors } from "../../store/books/books.selectors";
import './error.styles.scss'

const Error = () => {
  const error = useSelector(booksSelectors.selectError);
  console.error(error);

  return (
    <div className="error-container">
      <div className="error-container-in">
        <span className="header">_ERROR</span><br/>
        <span className="description">{error}</span><br/>
      </div>
    </div>
  );
}

export default Error;
