import './App.scss';
import Home from "./routes/home/home.component";
import { Route, Routes } from "react-router-dom";
import ContactUs from "./routes/contact-us/contact-us.component";
import Faq from "./routes/faq/faq.component";
import PrivacyPolicyAndTerms from "./routes/privacy-policy-and-terms/privacy-policy-and-terms.component";
import About from "./routes/about/about.component";
import NotFound from "./routes/not-found/not-found.component";
import SiteLayout from "./routes/site-layout/site-layout.component";
import SearchResultsPage from "./routes/search-results/search-results-page.component";
import Error from "./routes/error/error.component";
import BookDetails from "./routes/book-details/book-details.component";
import Articles from "./routes/articles/articles.component";
import ArticlesMainPage from "./routes/articles-main-page/articles-main-page.component";

function App() {
  return (
      <Routes>
        <Route path='/' element={<SiteLayout/>}>
          <Route index element={<Home/>}></Route>
          <Route path='search' element={<SearchResultsPage />}></Route>
          <Route path='book/:id/:title' element={<BookDetails />}></Route>
          <Route path='contact-us' element={<ContactUs/>}></Route>
          <Route path='faq' element={<Faq/>}></Route>
          <Route path='privacy-policy-and-terms' element={<PrivacyPolicyAndTerms/>}></Route>
          <Route path='about' element={<About/>}></Route>
          <Route path='articles/:id?/:title?' element={<Articles/>}></Route>
          <Route path='error' element={<Error/>}></Route>
          <Route path=':articleUrlName' element={<ArticlesMainPage/>}></Route>
          <Route path="*" element={<NotFound/>}/>
        </Route>
      </Routes>
  );
}

export default App;
