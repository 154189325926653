//import sleep from "sleep-promise";
export const fetchBooks = () => {
  const booksJsonUrl = '/data/PublicationUTF.JSON';
  return fetchData(booksJsonUrl);
}

export const fetchArticles = () => {
  const articles = '/data/Articles.JSON';
  return fetchData(articles);
}

const fetchData = (url) => {
  const nc = Date.now();
  return fetch(url + "?nc=" + nc,
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
  ).then((response) => {
    return response.json()
  })//.then(sleep(5000));
}



