const About = () => {
  return (<div>
    <h2>About</h2>

    <p><b>[readings.world]</b> offers unique publications in foreign languages on the market with a handy dictionary. At the beginning of its activity, the Publishing House offered only printed book publications with a dictionary in Polish. Currently, the Publishing House has expanded its offer to include items available online with dictionaries in the most popular languages.
    Gradually, our offer will be available in the form of printed books and ebooks on Amazon's bookstore.</p>

    <p>Surely every person who has ever learned a foreign language has tried to read a book in that language and...in most cases, was discouraged from the endeavor. Too many vocabulary words needed to be found in the dictionary, and all the pleasure of reading was replaced by the difficulty of translation. Some ebooks have built-in dictionaries - unfortunately, having to constantly click on an unfamiliar word makes it inconvenient.</p>

    <p>A series of publications developed and published by [readings.world] aims to make reading novels easier to understand. Unlike bilingual publications, it leaves the reader to interpret meanings and contexts, and thus makes reading in a foreign language not only enjoyable, but also develops language skills. Each book published by [readings.world] Publishing House has 5 levels of difficulty: A1, A2, B1, B2, C1. This means that a person who knows the language at a given level, (e.g. A2) will receive a publication containing a dictionary with words more difficult than A2.</p>

    <p>A handy dictionary in the margin of each page - includes all new words that are not included in the dictionary of the most common words.</p>

    <p><b><i>We wish you a successful reading experience!</i></b></p>
  </div>);
}

export default About;
