import './articles.styles.scss'
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { convertTitleToUrlParam } from "../../utils/params/params.utils";
import { useSelector } from "react-redux";
import { articlesSelectors } from "../../store/articles/articles.selectors";
import parse from 'html-react-parser'

const Articles = () => {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const { id } = useParams();
  const articles = useSelector(articlesSelectors.selectAllArticlesAtArticleContext);

  function getArticleLink(article) {
    const title = convertTitleToUrlParam(article.title);
    return `/articles/${article.id}/${title}`;
  }

  useEffect(() => {
    let filtered = articles
      .find(article => (article.id === id || article.id === Number(id)));
    setSelectedArticle(filtered);
  }, [articles, id]);

  return (
    <div className="articles-container">
      {!selectedArticle &&
        <div>
          <h1>Articles</h1>
          <div className="menu">
            <ul>
              {
                articles.map(article =>
                  <li key={article.id}><Link to={getArticleLink(article)}>{article.title}</Link></li>)
              }
            </ul>
          </div>
        </div>
      }
      {
        selectedArticle &&
        <div className="article">
          <h1>{selectedArticle.title}</h1>
          <div className="articleContent">
            {
              selectedArticle.paragraphs.map(p => (
                <div key={p.header} className="paragraph">
                  <h2>{p.header}</h2>
                  <p>{parse(p.content)}</p>
                </div>
              ))
            }
          </div>
        </div>
      }
    </div>
  )
}

export default Articles;
