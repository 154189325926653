import { Link } from "react-router-dom";
import { ReactComponent as ReadingsWorldLogo } from '../../assets/Logo.svg'
import { ReactComponent as MobileMenuOpenBtn } from '../../assets/Mobile-menu.svg'
import { ReactComponent as MobileMenuCloseBtn } from '../../assets/Mobile-menu-close.svg'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchBoxActions } from "../../store/search-box/search-box.actions";
import { searchBoxSelectors } from "../../store/search-box/search-box.selectors";

import './header.styles.scss'
import { useDesktopCheck } from "../../utils/custom-hooks/custom-hooks.utils";

const Header = () => {
  const mobileMenuIsOpen = useSelector(searchBoxSelectors.selectIsSearchBoxOpen);
  const dispatch = useDispatch();
  const isSearchBoxOpen = useSelector(searchBoxSelectors.selectIsSearchBoxOpen);
  const isDesktop = useDesktopCheck();

  const toggleMenu = () => {
    const newIsOpen = !mobileMenuIsOpen;
    dispatch(searchBoxActions.setIsSearchBoxOpen(newIsOpen));
  }

  useEffect(() => {
    if (isDesktop) {
      dispatch(searchBoxActions.setIsSearchBoxOpen(false));
    }
  }, [dispatch, isDesktop])

  return (
    <div className="header-container">
      <div className="header-in max-width-limiter">
        <Link to='/'><ReadingsWorldLogo className="logo"/></Link>
        <div className="title">
          <div className="title-in">
            <div>English Books With Dictionary</div>
          </div>
        </div>
        <div className="navigation">
          <div className="navigation-in">
            <Link className="btn btn-link"
                  variant="link"
                  role="button"
                  to="/articles">Articles</Link>
            <Link className="btn btn-link"
                  variant="link"
                  role="button"
                  to="/about">About</Link>
            <Link className="btn btn-primary"
                  variant="primary"
                  to="/contact-us">Contact Us</Link>
          </div>
        </div>
        <div className="navigation-mobile">
          <div className="mobile-menu-toggler" onClick={toggleMenu}>
            {
              isSearchBoxOpen ? <MobileMenuCloseBtn className='mobile-menu-close-btn'/> : <MobileMenuOpenBtn/>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;
