import { combineReducers } from "redux";
import { searchBoxReducer } from "./search-box/search-box.reducer";
import { booksReducer } from "./books/books.reducer";
import { articlesReducer } from "./articles/articles.reducer";

export const rootReducer = combineReducers({
  searchBox: searchBoxReducer,
  books: booksReducer,
  articles: articlesReducer
})
