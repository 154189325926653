import "./faq-footer.styles.scss"
import { useSelector } from "react-redux";
import { articlesSelectors } from "../../store/articles/articles.selectors";
import { Link } from "react-router-dom";
import { convertTitleToUrlParam } from "../../utils/params/params.utils";

const FaqFooter = () => {
  const articles = useSelector(articlesSelectors.selectAllArticlesAtNoContext);

  function getArticleLink(article) {
    const title = convertTitleToUrlParam(article.title);
    return `/article_${article.id}_${title}`;
  }

  return (
    <div className="faq-container">
      <h2 className="question">Why read English books online?</h2>
      <div className="answer">
        It is common knowledge that a human being learns a foreign language best when a specific goal is set or when
        learning is fun. By learning from typical textbooks, the content of which is based on boring, unattractive
        material it can be difficult to achieve. However, what if this content was a thrilling crime story, great SF or
        a classic romance? Moreover, if the content included real phrases and grammatical constructions? If it was
        possible to learn real language use and have fun at the same time? This is how the idea of online English books
        with a dictionary was born. Thanks to them you will be able to recognise the meaning in context, you will become
        familiar with idioms, mental shortcuts and phrases often derived from dialects. In a nutshell, it is a real
        language that we can encounter every day.
        We believe that reading in English online is one of the best complementary ways of learning English. Each and
        every contact with a living language is valuable and improves our language skills.
      </div>
      <h2 className="question">Where to find Free English books?</h2>
      <div className="answer">
        You do not have to search anymore. On this website you will find over 100 classic titles, bestsellers of English
        literature with a handy glossary in the margin. Our offer of free online English books is constantly being
        widened as well as languages of dictionaries in the margin!
      </div>
      <h2 className="question">Do English Bilingual books work?</h2>
      <div className="answer">
        There are plenty of English bilingual books available on the market. Such items have the original text on one
        side and a translation into another language on the other. Unfortunately, the feedback is that:
        <ul>
          <li>First of all - the readers experience difficulties in finding the meaning of a missing, unknown word on
            the
            other side of the book (it often requires reading the whole sentence or even the whole paragraph of the text
            which may be very time consuming),
          </li>
          <li>Secondly - it is very common that the translation of a given text into another language is the
            interpretation of a translator who has already done all the translation work.
          </li>
          <li>Thirdly - words in the text may have different meanings and the translation of the text gives only the
            interpretation proposed by the translator.
          </li>
          <li>What is more - when a reader is confronted with the option of reading in either their native or a foreign
            language, it requires a lot of determination on their side to focus on reading in the original. We have
            often received feedback that after a few paragraphs, the reader starts to read only the text in their native
            language.
          </li>
        </ul>
      </div>
      <h2 className="question">Articles</h2>
      <div className="answer">
        <ul>
        {
          articles.map(article => (
            <li key={article.id}><Link to={getArticleLink(article)}>{article.title}</Link></li>
          ))
        }
        </ul>
        </div>
    </div>
  );
}

export default FaqFooter;
