import { useSelector } from "react-redux";
import { booksSelectors } from "../../store/books/books.selectors";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import _ from "lodash";
import NotFound from "../not-found/not-found.component";
import { formatBookPublishDate, getDefaultLanguage, getLevels } from "../../utils/books/books.utils";
import './book-details.styles.scss'
import Img from "../../components/img/img.component";
import { useDesktopCheck } from "../../utils/custom-hooks/custom-hooks.utils";
import SelectLevels from "../../components/select-levels/select-levels.component";
import parse from "html-react-parser";

const BookDetails = () => {
  const [book, setBook] = useState({});
  const [levels, setLevels] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const booksMap = useSelector(booksSelectors.selectAllBooksAsMap);
  const isDesktop = useDesktopCheck();
  const isMobile = !useDesktopCheck();

  useEffect(() => {
      const languageFromSearchParam = searchParams.get('l');
      if (!languageFromSearchParam) {
        const defaultLang = getDefaultLanguage(window.navigator.language);
        setSearchParams({ 'l': defaultLang })
      }
      const book = booksMap.get(id);
      setBook(book);
      const levels = getLevels(book, languageFromSearchParam);
      setLevels(levels);
    },
    [id, booksMap, searchParams, setSearchParams]);

  const getBookTitleByLanguage = () => {
    return book['Book' + searchParams.get('l') + 'Title'];
  }

  return (
    _.isEmpty(book) ?
      <NotFound/> :
      <div className="book-details-container">
        <div className="book-short-info">
          {isMobile && <div className="uploaded">Uploaded: {formatBookPublishDate(book.BookPublicationDate)}</div>}
          <div className="cover-and-title-category-author">
            <div className="cover">
              <Img src={book.BookCoverPictureLink} alt={`Book cover for ${book.BookEnglishTitle}`}/>
            </div>
            <div className="title-category-author">
              {isMobile && <div className="title">{book.BookEnglishTitle}</div>}
              {isMobile && <div className="title-translated">{getBookTitleByLanguage()}</div>}
              {isDesktop && <div className="uploaded">Uploaded: {formatBookPublishDate(book.BookPublicationDate)}</div>}
              <div className="category"><span className="label">
                Category:</span> <Link
                to={`/search?c=${encodeURIComponent(book.BookCategory)}&l=${searchParams.get('l')}`}>{book.BookCategory}</Link>
              </div>
              <div className="author"><span className="label">
                Author:</span> <a href={book.BookAuthorWikipediaLink} target="_blank"
                                  rel="noopener noreferrer">{book.BookAuthor}</a></div>
              {isDesktop && <div className="title">{book.BookEnglishTitle}</div>}
              {isDesktop && <div className="title-translated">{getBookTitleByLanguage()}</div>}
              {isDesktop && <div className="book-long-info">
                <div className="book-levels-label">Choose Your English Dictionary Level:</div>
                <SelectLevels levels={levels} />
              </div> }
              {isDesktop && <div className="description description-desktop">{parse(book.BookEnglishDescription)}</div>}
              {isDesktop && <div className="title title-lang">{getBookTitleByLanguage()}</div>}
              {isDesktop && <div
                className="description description-translated">{parse(book['Book' + searchParams.get('l') + 'Description'])}</div>}
            </div>
          </div>
          {isMobile && <div className="book-long-info">
            <div className="book-levels-label">Choose Your English Dictionary Level:</div>
            <SelectLevels levels={levels} />
          </div> }
          {isMobile && <div className="description description-mobile">{parse(book.BookEnglishDescription)}</div>}
          {isMobile && <div className="title title-lang">{getBookTitleByLanguage()}</div>}
          {isMobile && <div
            className="description description-translated">{parse(book['Book' + searchParams.get('l') + 'Description'])}</div>}
        </div>
      </div>

  );
}

export default BookDetails;
