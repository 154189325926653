import { rootReducer } from "./root-reducer";
import { applyMiddleware, compose, createStore } from "redux";
import { logger } from "redux-logger";
import thunk from "redux-thunk";

const middleWares = [
  process.env.NODE_ENV !== 'production' && false && logger,
  thunk
].filter(Boolean)
const composeEnhancer = (process.env.NODE_ENV !== 'production' &&
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  || compose;
const composedEnhancers = composeEnhancer(applyMiddleware(...middleWares));

export const store = createStore(rootReducer, undefined, composedEnhancers);
