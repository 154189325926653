import { convertTypes } from "../../utils/books/books.utils";
import { createSelector } from "reselect";


const selectArticlesReducer = (state) => state.articles;

const selectAllArticles = createSelector(
  [selectArticlesReducer],
  (articles) => convertTypes(articles.articlesList));

const selectAllArticlesAtArticleContext = createSelector(
  [selectAllArticles],
  (articles) => articles.filter(article => article.context === 'articles'));

const selectAllArticlesAtNoContext = createSelector(
  [selectAllArticles],
  (articles) => articles.filter(article => article.context === ''));

const selectError = createSelector(
  [selectArticlesReducer],
  (articles) => articles.error);

const selectArticlesIsLoading = createSelector(
  [selectArticlesReducer],
  (articles) => articles.isLoading);

export const articlesSelectors = {
  selectAllArticles,
  selectAllArticlesAtArticleContext,
  selectAllArticlesAtNoContext,
  selectError,
  selectArticlesIsLoading
}
