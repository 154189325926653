import moment from "moment/moment";
import sortArray from "sort-array";
import MiniSearch from "minisearch";
import _ from 'lodash';

const prefix = "Book";
const postfix = "Description";
const levelPostfix = "Link";

export const createOptionsFromField = (booksList, fieldName) => {
  return [...new Set(booksList.map(book => book[fieldName]))]
    .filter(field => field != null && field.length > 0)
    .map(field => {
      return {
        value: field,
        label: field
      }
    })
}

export const createLanguageOptions = (booksList) => {
  return [...new Set(booksList
    .map(book => findLanguagesFromFields(book))
    .flat())]
    .filter(lang => isNotEmpty(lang))
    .filter(lang => lang.toLowerCase() !== 'english')
    .map(lang => {
      return {
        value: lang,
        label: lang
      }
    });
}

/*
filter example:
filter = {
   q: 'text'
   l: ['Polish']
   c: ['cat1', 'cat2]
   a: ['autor1', 'autor2']
}

sort = {
  by: 'BookPublicationDate',
  order: 'desc'
}

limit = 10
*/

export const findBooksByFilter = (booksMap, booksIndex, filter, limit) => {
  const booksList = (_.isEmpty(booksMap)) ? [] : Array.from(booksMap.values());
  if (isEmpty(booksList)) {
    return booksList;
  }
  const filteredBookList = search(booksMap, booksList, booksIndex, filter);
  const sortedBookList = sort(filteredBookList, filter.s);
  return limitArray(sortedBookList, limit);
}

/*
result example:
{
    l: {
      "Polish": 10,
        "German": 10,
        "French": 10
    },
    c: {
      "Literature & Fiction": 5,
        "Children's Books": 12,
        "Mystery, Thriller & Suspense": 1
    },
    a: {
      "Jules Verne": 12,
        "Lewis Carroll": 20
    }
  }
 */

export const getPredictedCountInfo = (booksMap, booksIndex, options, filter) => {
  const booksList = (_.isEmpty(booksMap)) ? [] : Array.from(booksMap.values());
  if (_.isEmpty(booksList) || _.isEmpty(filter)) {
    return booksList;
  }
  const languagesList = options.languages.map(l => l.value);
  const categoriesList = options.categories.map(l => l.value);
  const authorsList = options.authors.map(l => l.value);
  return new Promise((resolve) => {
    const result = { l: {}, c: {}, a: {} };
    // languages
    languagesList.forEach(lang => {
      const filterCopy = _.cloneDeep(filter);
      if (!filterCopy.l) {
        filterCopy.l = [];
      }
      filterCopy.l = [lang];
      result.l[lang] = findBooksByFilter(booksMap, booksIndex, filterCopy).length;
    })

    // categories
    categoriesList.forEach(cat => {
      const filterCopy = _.cloneDeep(filter);
      if (!filterCopy.c) {
        filterCopy.c = [];
      }
      //TODO:  wer 1 - ile będzie po kliknięciu
      // filterCopy.c.push(cat);
      // filterCopy.c = [...new Set(filterCopy.c)];

      //TODO: wer 2 - ile jest w tej kategorii
      filterCopy.c = [cat]

      result.c[cat] = findBooksByFilter(booksMap, booksIndex, filterCopy).length;
    })

    // authors
    authorsList.forEach(author => {
      const filterCopy = _.cloneDeep(filter);
      if (!filterCopy.a) {
        filterCopy.a = [];
      }
      //TODO:  wer 1 - ile będzie po kliknięciu
      // filterCopy.a.push(author);
      // filterCopy.a = [...new Set(filterCopy.a)];

      //TODO: wer 2 - ile jest w tej kategorii
      filterCopy.a = [author]

      result.a[author] = findBooksByFilter(booksMap, booksIndex, filterCopy).length;
    })

    return resolve(result);
  })
}

export const convertBookPublishDateToDateType = (dateAsString) => {
  return moment(dateAsString, "DD/MM/YYYY").toDate();
}
export const formatBookPublishDate = (dateAsDateType) => {
  return moment(dateAsDateType).format('MMMM D, YYYY');
}

export const convertTypes = (bookList) => {
  const bookListCopy = structuredClone(bookList);
  bookListCopy.forEach(book => {
    book.BookPublicationDate = convertBookPublishDateToDateType(book.BookPublicationDate);
    book.BookPopularity = Number(book.BookPopularity);
    return book;
  });
  return bookListCopy;
}

export const getUniqueFields = (bookList) => {
  return [...new Set(bookList.map(book => Object.keys(book)).flat())];
}

export const createIndex = (bookList, storeFields) => {
  const index = new MiniSearch({
    fields: ['BookEnglishTitle', 'BookCategory', 'BookEnglishDescription', 'BookAuthor', 'BookPublicationDate'],
    storeFields,
    idField: 'BookID',
    searchOptions: {
      boost: { BookPublicationDate: 5, BookEnglishTitle: 3, BookEnglishDescription: 2 },
      fuzzy: 0.3
    },
    extractField: (document, fieldName) => {
      if (fieldName === 'BookPublicationDate') {
        const pubDate = document['BookPublicationDate']
        return pubDate && pubDate.getFullYear().toString()
      }
      // Access nested fields
      return fieldName.split('.').reduce((doc, key) => doc && doc[key], document)
    }
  })
  index.addAll(bookList);
  return index;
}

export const createMapById = (booksList) => {
  return booksList.reduce(
    (acc, book) => {
      acc.set(book.BookID, book);
      return acc;
    }, new Map());
}

export const getSortOptions = () => [
  { label: "Matching", value: "Matching" },
  { label: "Alphabetically", value: "Alphabetically" },
  { label: "Most popular", value: "Most popular" },
  { label: "Latest", value: "Latest" }
]

export const getDefaultLanguage = (languageLocale) => {
  const postfix = /-.*/ig;
  const lang = languageLocale.replaceAll(postfix, '')
  const localeLanguageMap = {
    "bg": "Bulgarian",
    "cs": "Czech",
    "da": "Danish",
    "de": "German",
    "el": "Greek",
    "es": "Spanish",
    "et": "Estonian",
    "fi": "Finnish",
    "fr": "French",
    "hu": "Hungarian",
    "id": "Indonesian",
    "it": "Italian",
    "ja": "Japanese",
    "lt": "Lithuanian",
    "lv": "Latvian",
    "nl": "Dutch",
    "pl": "Polish",
    "pt": "Portuguese",
    "ro": "Romanian",
    "ru": "Russian",
    "sk": "Slovak",
    "sl": "Slovenian",
    "sv": "Swedish",
    "tr": "Turkish",
    "uk": "Ukrainian",
    "zh": "Chinese"
  }
  const defaultLanguage = localeLanguageMap[lang]
  return defaultLanguage ? defaultLanguage : 'German';
}

/**
 * Returns
 * [{level:'A1', link:'http://linkt-to-book'}]
 */
export const getLevels = (book, language) => {
  if (!book) {
    return [];
  }
  const levelPrefix = prefix + language + 'Level';
  return Object.keys(book)
    .filter(key => {
      return key.startsWith(levelPrefix) && key.endsWith(levelPostfix);
    })
    .map(key => {
      return {
        level: key,
        link: book[key]
      }
    })
    .map(result => {
      return {
        level: result.level.replace(levelPrefix, "").replace(levelPostfix, ""),
        link: result.link
      };
    });
}

const containsAnyLanguage = (book, languages) => {
  if (_.isEmpty(languages)) {
    return true;
  }
  return languages.some(lang => book.hasOwnProperty(`${prefix}${lang}${postfix}`))
}
const containsAny = (book, field, values) => {
  return isNotEmpty(values) ? values.includes(book[field]) : true;
}

const searchWithoutIndex = (books, filter) => {
  if (isNotEmpty(filter.l)) {
    books = books.filter(book => containsAnyLanguage(book, filter.l));
  }
  if (isNotEmpty(filter.a)) {
    books = books.filter(book => containsAny(book, 'BookAuthor', filter.a));
  }
  if (isNotEmpty(filter.c)) {
    books = books.filter(book => containsAny(book, 'BookCategory', filter.c));
  }
  return books;
}

const searchWithIndex = (booksMap, index, filter) => {
  const results = index.search(filter.q, {
    filter: book => {
      return containsAnyLanguage(book, filter.l)
        && containsAny(book, 'BookAuthor', filter.a)
        && containsAny(book, 'BookCategory', filter.c)
    },
  })

  process.env.NODE_ENV !== 'production' && console.log('from index results: ', results);
  return results.map(result => result.id).map(id => booksMap.get(id));
}

const search = (booksMap, booksList, booksIndex, filter) => {
  let booksListTemp = booksList;

  if (!filter) {
    return booksListTemp;
  }
  if (isNotEmpty(filter.q)) {
    booksListTemp = searchWithIndex(booksMap, booksIndex, filter);
  } else {
    booksListTemp = searchWithoutIndex(booksListTemp, filter);
    return booksListTemp;
  }
  return booksListTemp;
}

const sort = (booksList, sortFromFilter) => {
  if (_.isEmpty(sortFromFilter)) {
    return booksList;
  }

  let sortObj;
  switch (sortFromFilter[0]) {
    case "Alphabetically":
      sortObj = { by: 'BookEnglishTitle', order: 'asc' }
      break;
    case "Most popular":
      sortObj = { by: 'BookPopularity', order: 'desc' }
      break;
    case "Latest":
      sortObj = { by: 'BookPublicationDate', order: 'desc' }
      break;
    case "Matching":
    default:
      return booksList;
  }
  return sortArray(booksList, sortObj);
}

const isNotEmpty = (obj) => {
  return obj && obj.length > 0
}
const isEmpty = (obj) => {
  return !isNotEmpty(obj);
}
const limitArray = (booksList, limit) => {
  if (limit && limit > 0) {
    return booksList.slice(0, limit);
  }
  return booksList;
}

const findLanguagesFromFields = (obj) => {

  return [...new Set(Object.keys(obj)
    .filter(key => key.startsWith(prefix) && key.endsWith(postfix))
    .map(key => key.replace(prefix, "").replace(postfix, "")))];
}
