import { useMediaQuery } from "react-responsive";
import { createSearchParams, useNavigate } from "react-router-dom";

export const useDesktopCheck = () => {
  return !useMediaQuery({ query: '(max-width: 768px)' });
};

export const useNavigateSearch = () => {
  const navigate = useNavigate();
  return (params, pathname = '/search') => {
    const paramsWithoutNulls = Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null));
    navigate({ pathname, search: `?${createSearchParams(paramsWithoutNulls)}` });
  }
};
