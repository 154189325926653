import './slogan.styles.scss'

const Slogan = () => {
  return (
    <div className="slogan-container">
      <h1><span className="primary-color">Learn</span> English <span className="primary-color">Reading</span> English</h1>
      <h2>What would you like to read today <strong>for free</strong>?</h2>
    </div>
  )
}

export default Slogan;
