import { createAction } from "../../utils/reducer/reducer.utils";
import { fetchArticles } from "../../utils/http/http.utils";
import { ARTICLES_ACTION_TYPES } from "./articles.types";

export const articlesActions = {
  fetchArticlesStart: () => createAction(ARTICLES_ACTION_TYPES.FETCH_ARTICLES_START),
  fetchArticlesSuccess: (articles) => createAction(ARTICLES_ACTION_TYPES.FETCH_ARTICLES_SUCCESS, articles),
  fetchArticlesFailed: (error) => createAction(ARTICLES_ACTION_TYPES.FETCH_ARTICLES_FAILED, error),
  fetchArticlesAsync: () => async (dispatch) => {
    dispatch(articlesActions.fetchArticlesStart());
    try {
      const articleList = await fetchArticles()
      dispatch(articlesActions.fetchArticlesSuccess(articleList));
    } catch (e) {
      dispatch(articlesActions.fetchArticlesFailed(e.message));
    }
  }
}
