import { Button, Form } from "react-bootstrap";
import { ReactComponent as SearchIcon } from '../../assets/Search-icon.svg'
import { ReactComponent as XIcon } from '../../assets/X-icon.svg'
import { useEffect, useRef, useState } from "react";

import './search-input.styles.scss'
import { useDesktopCheck } from "../../utils/custom-hooks/custom-hooks.utils";

const SearchInput = ({ value, onChange, onSearchClick, compact }) => {
  const isDesktop = useDesktopCheck();
  const inputReference = useRef(null);
  const [valueState, setValueState] = useState('');

  useEffect(() => {
    setValueState(value ? value : '')
  }, [value])

  const onChangeHandler = (event) => {
    setValueState(event.target.value);
    onChange(event.target.value);
  }

  const clear = () => {
    setValueState('');
    onChange('');
    inputReference.current.focus();
    isDesktop && onSearchClick();
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearchClick();
    }
  };

  const handleBlur = () => {
    isDesktop && onSearchClick();
  }

  return (
    <div className={`search-input-container ${!compact ? 'search-input-container-desktop' : ''}`}>
      <Form.Control value={valueState}
                    ref={inputReference}
                    onChange={onChangeHandler}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    className="input"
                    placeholder="Search"/>
      {
        valueState.length > 0 &&
        <div className="clear-button" onClick={clear}>
          <XIcon/>
        </div>
      }
      <Button className="search-button" variant="secondary" onClick={onSearchClick}><SearchIcon/></Button>
    </div>
  );
}

export default SearchInput;
