import './not-found.styles.scss'
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-container-in">
        <span className="header">_404</span><br />
        <span className="description">The page you are looking for does not exist. It may have been moved, or removed
        altogether.</span><br />
        <span className="home-button"><Link className="btn btn-primary" variant="primary" to="/">Back to Homepage</Link></span>
      </div>
    </div>
  );
}

export default NotFound;
