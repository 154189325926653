import './search-result.styles.scss'
import { Button } from "react-bootstrap";
import { useDesktopCheck } from "../../utils/custom-hooks/custom-hooks.utils";
import { formatBookPublishDate } from "../../utils/books/books.utils";
import { Link, useSearchParams } from "react-router-dom";
import { convertSearchParamsToObject, convertTitleToUrlParam } from "../../utils/params/params.utils";
import Img from "../img/img.component";
import parse from "html-react-parser";

const SearchResult = ({ book }) => {
  const isDesktop = useDesktopCheck();
  const [searchParams] = useSearchParams();

  const bookLink = () => {
    const language = convertSearchParamsToObject(searchParams).l;
    return `/book/${book.BookID}/${convertTitleToUrlParam(book.BookEnglishTitle)}` + (language ? '?l=' + language : '');
  }

  const getBookTitleByLanguage = () => {
    return book['Book' + searchParams.get('l') + 'Title'];
  }

  return (
    <div className="search-result-container">
      {
        isDesktop ?
          <div className="search-result-container-desktop">
            <div className="cover-and-uploaded-title-category-author">
              <div className="cover">
                <Link to={bookLink()}>
                <Img src={book.BookCoverPictureLink}
                     alt={`Book cover for ${book.BookEnglishTitle}`}/></Link>
              </div>
              <div className="uploaded-title-description-category-author">
                <div className="uploaded">
                  <span className="label">Uploaded:</span> {formatBookPublishDate(book.BookPublicationDate)}</div>
                <div className="title"><Link to={bookLink()}>{book.BookEnglishTitle}</Link></div>
                <div className="title-translated">{getBookTitleByLanguage()}</div>
                <div className="description">{parse(book.BookEnglishDescription)}</div>
                <div className="category"><span className="label">Category:</span> {book.BookCategory}</div>
                <div className="author"><span className="label">Author:</span> {book.BookAuthor}</div>
              </div>
            </div>

            <div className="read-book-button-container">
              <Link to={bookLink()}>
                <Button className="read-book-button"
                        variant="primary">Read Book</Button>
              </Link>

            </div>
          </div>
          :
          <div className="search-result-container-mobile">
            <div className="uploaded">Uploaded: {formatBookPublishDate(book.BookPublicationDate)}</div>
            <div className="cover-and-title-category-author">
              <div className="cover">
                <Link to={bookLink()}><Img src={book.BookCoverPictureLink}
                     alt={`Book cover for ${book.BookEnglishTitle}`}/></Link>
              </div>
              <div className="title-category-author">
                <div className="title"><Link to={bookLink()}>{book.BookEnglishTitle}</Link></div>
                <div className="title-translated">{getBookTitleByLanguage()}</div>
                <div className="category"><span className="label">Category:</span> {book.BookCategory}</div>
                <div className="author"><span className="label">Author:</span> {book.BookAuthor}</div>
              </div>
            </div>
            <div className="description">{parse(book.BookEnglishDescription)}</div>
            <div className="read-book-button-container">
              <Link to={bookLink()}>
                <Button className="read-book-button"
                        variant="primary">Read Book</Button>
              </Link>
            </div>
          </div>
      }
    </div>
  );
}
export default SearchResult;
