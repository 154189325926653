import SearchResult from "../../components/search-result/search-result.component";

import './search-results-page.styles.scss'
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { findBooksByFilter, getDefaultLanguage, getSortOptions } from "../../utils/books/books.utils";
import { useSelector } from "react-redux";
import { booksSelectors } from "../../store/books/books.selectors";
import { convertSearchParamsToObject, searchParamsToString } from "../../utils/params/params.utils";
import Select from "../../components/select/select.component";
import { useDesktopCheck, useNavigateSearch } from "../../utils/custom-hooks/custom-hooks.utils";
import _ from 'lodash';
import FaqFooter from "../../components/faq-footer/faq-footer.component";

const SearchResultsPage = () => {
  const booksList = useSelector(booksSelectors.selectAllBooks);
  const booksIndex = useSelector(booksSelectors.selectAllBooksIndex);
  const booksMap = useSelector(booksSelectors.selectAllBooksAsMap);

  const [filters, setFilters] = useState({});
  const [filteredBooks, setFilteredBooks] = useState(booksList);

  const [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const isDesktop = useDesktopCheck();

  useEffect(() => {
    const filter = convertSearchParamsToObject(searchParams);
    setFilters(convertSearchParamsToObject(searchParams));
    const findBooksByFilterTmp = findBooksByFilter(booksMap, booksIndex, filter);
    setFilteredBooks(findBooksByFilterTmp);
  }, [searchParams, booksList, booksIndex, booksMap])

  useEffect(() => {
    const filter = convertSearchParamsToObject(searchParams);
    const lang = getDefaultLanguage(window.navigator.language);
    if (_.isEmpty(filter.l)) {
      filter.l = [lang];
      navigateSearch(filter, '/search');
    }
  }, [navigateSearch, searchParams])

  const handleSortChange = (selectedSort) => {
    filters.s = selectedSort;
    setFilters(filters);
    navigateSearch(filters);
  }

  return (
    <div className="search-result-page-container">
      <div className="filter-summary-and-sorting">
        <div className="filter-summary">
          <span className="results-count">{filteredBooks.length}
            {filteredBooks.length === 1 ? ' Result: ' : ' Results: '}</span>
          {searchParamsToString(searchParams)}
        </div>
        <div className="sorting">
          <div className="row">
            { isDesktop &&
              <div className="col">Sort</div> }
            <div className="col col-select"><Select name="sort-select"
                         value={!_.isEmpty(filters.s) ? filters.s : ["Matching"]}
                         isMulti={false}
                         onChange={handleSortChange}
                         isSearchable={false}
                         displayResultCount={false}
                         options={getSortOptions()}
                         compact={true}/>
            </div>
          </div>
        </div>
      </div>
      <div className="results">
        {
          filteredBooks.map((book) =>
            <SearchResult book={book} key={book.BookID}/>
          )
        }
      </div>
      <FaqFooter />
    </div>
  )
}

export default SearchResultsPage
