export const convertSearchParamsToObject = (searchParams) => ({
  q: searchParams.get('q'),
  l: searchParams.getAll('l'),
  c: searchParams.getAll('c'),
  a: searchParams.getAll('a'),
  s: searchParams.getAll('s')
});

export const searchParamsToString = (searchParams) => {
  const paramsAsObject = convertSearchParamsToObject(searchParams);
  return [paramsAsObject.l, paramsAsObject.c, paramsAsObject.a, paramsAsObject.q]
    .filter(param => param != null && param.length > 0)
    .join(", ")
}


export const convertTitleToUrlParam = (title) => {
  return title.replaceAll(" ", "_").toLowerCase();
}
