import { ReactComponent as ReadingsWorldLogoWhite } from '../../assets/Logo-white.svg'
import { ReactComponent as FacebookLogo } from '../../assets/Facebook.svg'
import { ReactComponent as InstagramLogo } from '../../assets/Instagram.svg'
import './footer.styles.scss'
import { Link } from "react-router-dom";
import buildInfo from "../../buildInfo";
import Moment from 'moment';
const Footer = () => {
  const date = Moment(buildInfo.buildDate).format('YYYY-MM-DD hh:mm');
  return (
    <>
      <div className="footer-container max-width-limiter">
        <div className="footer-container-in">
          <div className="footer-top row row-cols-1 row-cols-md-2">
            <div className="footer-top-left col-md-8">
              <ReadingsWorldLogoWhite className="logo"/>
              <div className="text">
                Readings.World is a site that allows you to read books in English with a handy dictionary in the margins so you can understand the content even without knowing the vocabulary well. Learn English by reading in English!
              </div>
              <div className="links">
                <Link to="/articles">Articles</Link>
                <Link to="/faq">FAQ</Link>
                <Link to="/contact-us">Contact Us</Link>
                <Link className="link-about" to="/about">About</Link>
                <Link to="/privacy-policy-and-terms">Privacy Policy & Terms</Link>
              </div>
            </div>
            <div className="footer-top-right col-md-4">
              <a href="https://www.facebook.com/zeslownikiem" target="_blank" rel="noreferrer" className="social-logo facebook-logo"><FacebookLogo/></a>
              <a href="https://www.instagram.com/zeslownikiem/" target="_blank" rel="noreferrer" className="social-logo instagram-logo"><InstagramLogo/></a>
              {/*<a href="https://twitter.com" className="social-logo twitter-logo"><TwitterLogo/></a>*/}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-text">
          Copyrights © {date} - Readings World ({buildInfo.buildVersion})
        </div>
      </div>
    </>
  )
}

export default Footer;
