import { components } from "react-select";

import './option.styles.scss'

const SelectItemOption = ({label, isSelected, ...props}) => {
  const { predictedCountInfo } = props.selectProps.selectProps;

  return (
    <div className="select-item-option-container">
      <components.Option {...props}>
        <div className="select-item-option-container-in">
          <input
            className="form-check-input"
            type="checkbox"
            checked={isSelected}
            onChange={() => null}
          />{" "}
          <label>{label}</label>
          {
            props.selectProps.selectProps.displayResultCount &&
            <span className="resultCount">{predictedCountInfo[label] ? predictedCountInfo[label] : 0}</span>
          }
        </div>
      </components.Option>
    </div>
  );
};

export default SelectItemOption
