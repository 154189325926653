import { BOOKS_ACTION_TYPES } from "./books.types";

const INITIAL_STATE = {
  booksList: [],
  isLoading: false,
  error: null
}
export const booksReducer = (state = INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case BOOKS_ACTION_TYPES.FETCH_BOOKS_START:
      return { ...state, isLoading: true };
    case BOOKS_ACTION_TYPES.FETCH_BOOKS_SUCCESS:
      return { ...state, booksList: payload, isLoading: false };
    case BOOKS_ACTION_TYPES.FETCH_BOOKS_FAILED:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
}
