const Img = ({ src, defaultSrc, alt, ...props }) => {
  const handleImageError = ({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    if(!defaultSrc) {
      currentTarget.src = "/images/temp-book-cover.png";
    } else {
      currentTarget.src = defaultSrc;
    }
  }
  return (
    <img src={src} alt={alt} { ...props } onError={handleImageError}/>
  );
}

export default Img;
