import SearchResult from "../../components/search-result/search-result.component";
import { findBooksByFilter, getDefaultLanguage } from "../../utils/books/books.utils";
import { useSelector } from "react-redux";
import { booksSelectors } from "../../store/books/books.selectors";
import { useEffect, useState } from "react";
import { ReactComponent as LinkArrow } from '../../assets/Link-arrow.svg'

import "./home.styles.scss"
import { Link, useSearchParams } from "react-router-dom";
import { useNavigateSearch } from "../../utils/custom-hooks/custom-hooks.utils";
import { convertSearchParamsToObject } from "../../utils/params/params.utils";
import _ from 'lodash';
import FaqFooter from "../../components/faq-footer/faq-footer.component";

const Home = () => {
  const booksMap = useSelector(booksSelectors.selectAllBooksAsMap);
  const [lang, setLang] = useState();
  const [searchParams] = useSearchParams()
  const navigateSearch = useNavigateSearch();
  const [newReleasedBooks, setNewReleasedBooks] = useState(Array.from(booksMap.values()));
  const [mostPopularBooks, setMostPopularBooks] = useState(Array.from(booksMap.values()));

  useEffect(() => {
    const filter = convertSearchParamsToObject(searchParams);
    const newReleased = findBooksByFilter(booksMap, null, { ...filter, s: ['Latest'] },  3);
    setNewReleasedBooks(newReleased);
    let mostPopular = findBooksByFilter(booksMap, null, { ...filter, s: ['Most popular'] }, 3);
    setMostPopularBooks(mostPopular);
  }, [booksMap, searchParams])

  useEffect(() => {
    const filter = convertSearchParamsToObject(searchParams);
    const lang = getDefaultLanguage(window.navigator.language);
    if (_.isEmpty(filter.l)) {
      filter.l = [lang];
      navigateSearch(filter, '/');
    }
    setLang(lang);
  }, [navigateSearch, searchParams])

  return (
    <div className="home-container">
      <div className="mobile-splitter"></div>
      <div className="header-and-link-to-all">
        <h2>Most Popular Titles</h2>
        <div className="link-to-all">
          <div className="arrow"><LinkArrow /></div>
          <div className="link"><Link to={`/search?s=Most+popular&l=${lang}`}>See all</Link></div>
        </div>
      </div>
      <div className="most-popular-titles-container">
        {
          mostPopularBooks.map(book =>
            <SearchResult book={book} key={`most-popular-${book.BookID}`}/>
          )
        }
      </div>
      <div className="header-and-link-to-all">
        <h2 className="new-releases-header">New Releases</h2>
        <div className="link-to-all">
          <div className="arrow"><LinkArrow /></div>
          <div className="link"><Link to={`/search?s=Latest&l=${lang}`}>See all</Link></div>
        </div>
      </div>
      <div className="new-releases-container">
        {
          newReleasedBooks.map(book =>
            <SearchResult book={book} key={`new-release-${book.BookID}`}/>
          )
        }
      </div>
      <FaqFooter />
    </div>
  );
}

export default Home;
