import './spinner.styles.scss'

const Spinner = () => (
  <div className="spinner-container">
    <div className="spinner-overlay">
      <div className="spinner-container-in"/>
    </div>
    <div className="spinner-label"><i>Loading books...</i></div>
  </div>
);

export default Spinner;
