import { ReactComponent as BannerImage1 } from '../../assets/Banner-part1.svg'
import { ReactComponent as BannerImage2 } from '../../assets/Banner-part2.svg'
import { ReactComponent as BannerImage3 } from '../../assets/Banner-part3.svg'
import { ReactComponent as BannerImage4 } from '../../assets/Banner-part4.svg'
import { ReactComponent as BannerImage5 } from '../../assets/Banner-part5.svg'

import './banner.styles.scss'

const Banner = () => {
  return (
    <div className="banner-container">
      <h1>Start reading books with dictionary is easy as:</h1>
      <div className="banner-container-in row">
        <div className="single-banner col"><BannerImage1 /></div>
        <div className="single-banner col"><BannerImage2 /></div>
        <div className="single-banner col"><BannerImage3 /></div>
        <div className="single-banner col"><BannerImage4 /></div>
        <div className="single-banner col"><BannerImage5 /></div>
      </div>
    </div>
  );
}

export default Banner
