import './articles-main-page.styles.scss'
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { articlesSelectors } from "../../store/articles/articles.selectors";
import parse from 'html-react-parser'
import NotFound from "../not-found/not-found.component";

const ArticlesMainPage = () => {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const { articleUrlName } = useParams();
  const articles = useSelector(articlesSelectors.selectAllArticles);

  useEffect(() => {
    const split = articleUrlName.split("_");
    const id = split[1];
    let filtered = articles
      .find(article => (article.id === id || article.id === Number(id)));
    if (filtered != null) {
      setSelectedArticle(filtered);
    }
  }, [articles, articleUrlName]);

  return (
    <div className="articles-container">
      {
        selectedArticle &&
        <div className="article">
          <h1>{selectedArticle.title}</h1>
          <div className="articleContent">
            {
              selectedArticle.paragraphs.map(p => (
                <div key={p.header} className="paragraph">
                  <h2>{p.header}</h2>
                  <p>{parse(p.content)}</p>
                </div>
              ))
            }
          </div>
        </div>
      }
      {!selectedArticle && <NotFound />}
    </div>
  )
}

export default ArticlesMainPage;
