import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";
import SearchBox from "../../components/search-box/search-box.component";
import Slogan from "../../components/slogan/slogan.component";
import Banner from "../../components/banner/banner.component";

import './site-layout.styles.scss';
import { useDispatch, useSelector } from "react-redux";
import { searchBoxActions } from "../../store/search-box/search-box.actions";
import { searchBoxSelectors } from "../../store/search-box/search-box.selectors";
import { useDesktopCheck } from "../../utils/custom-hooks/custom-hooks.utils";
import { booksActions } from "../../store/books/books.actions";
import { useEffect, useState } from "react";
import { booksSelectors } from "../../store/books/books.selectors";
import Spinner from "../../components/spinner/spinner.component";
import { articlesActions } from "../../store/articles/articles.actions";

const SiteLayout = () => {
  const isSearchBoxOpen = useSelector(searchBoxSelectors.selectIsSearchBoxOpen);
  const [isPageWithSearchBox, setIsPageWithSearchBox] = useState(false);
  const error = useSelector(booksSelectors.selectError);
  const isLoading = useSelector(booksSelectors.selectBooksIsLoading);
  const isDesktop = useDesktopCheck();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(booksActions.fetchBooksAsync())
  }, [dispatch])

  useEffect(() => {
    dispatch(articlesActions.fetchArticlesAsync())
  }, [dispatch])

  useEffect(() => {
    if (error) {
      navigate({ pathname: '/error' });
    }
  }, [error, location, navigate])

  useEffect(() => {
    if (location.pathname &&
      (location.pathname === '/' || location.pathname.includes('/search'))) {
      setIsPageWithSearchBox(true);
    } else {
      setIsPageWithSearchBox(false);
    }

  }, [location.pathname])

  const closeMobileMenu = () => {
    dispatch(searchBoxActions.setIsSearchBoxOpen(false));
  }

  return (
    <>
      <header><Header/></header>
      <main>
        {
          !isSearchBoxOpen && location.pathname === '/' &&
          <div className="banner-and-slogan-container max-width-limiter">
            <div className="slogan"><Slogan/></div>
            <div className="banner"><Banner/></div>
          </div>
        }
        <div className="outlet-container max-width-limiter">
          {isLoading ? <Spinner/> :
            <div className="outlet-container-in">
              {
                isSearchBoxOpen ? <SearchBox compact={true} onSearch={closeMobileMenu}/> :
                  <>
                    {
                      isDesktop && isPageWithSearchBox &&
                      <div className="search-box-for-desktop">
                        <SearchBox compact={false}/>
                      </div>
                    }
                    <div className="outlet">
                      <Outlet/>
                    </div>
                  </>
              }
            </div>
          }
        </div>
      </main>
      <footer>
        <Footer/>
      </footer>
    </>
  )
}

export default SiteLayout
